import { useEffect } from 'react';
import { LandingFeatureNames } from '#types/LandingFeatureNames.ts';

interface RouteChangeCompleteListenerProps {
  feature?: keyof typeof LandingFeatureNames;
}

export default function RouteChangeCompleteListener({ feature }: RouteChangeCompleteListenerProps) {
  useEffect(() => {
    try {
      if (feature && LandingFeatureNames.hasOwnProperty(feature)) {
        sessionStorage.setItem('lastland', LandingFeatureNames[feature]);
      }
      if (typeof window !== 'undefined' && typeof window.onRouteChange === 'function') {
        window.onRouteChange(location.href);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  return '';
}
